import {
    Outlet
} from 'react-router-dom';
import GdanskPartners from "./components/GdanskPartners";
import ComingSoonPage from "../comingSoonPage/comingSoonPage";
import {useEffect} from "react";
import {fetchEventsState} from "../../Utilities/fetchEvents";
import {useAppDispatch} from "../../Store/hooks";

function Gdansk() {


    return (
        <>
            {/*<ComingSoonPage/>*/}

            <Outlet/>
            <GdanskPartners/>
        </>
    );
}

export default Gdansk

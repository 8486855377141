import React from "react";
import styled from "styled-components";
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import dateName from "../../../Utilities/dateConverter";
import {useNavigate} from "react-router-dom";

interface EventListProps {
    events: any[];
    isMobile: boolean
}

function EventList({events, isMobile}: EventListProps) {
    const navigate = useNavigate();

    return (
        <List>
            {!isMobile && <ListView>
                <ListItem isMobile={isMobile}>
                    <ItemSection isMobile={isMobile}>
                        <h5>Gdzie?</h5>
                    </ItemSection>
                    <VerticalSeparator/>
                    <ItemSection isMobile={isMobile}>
                        <h5>O której?</h5>
                    </ItemSection>
                </ListItem>
            </ListView>}
            {events.map((event, index) => (
                <ListView key={index}>
                    <ListItem isMobile={isMobile}>
                        <ItemSection isMobile={isMobile}>
                            {!isMobile && <img src={event.images[0].url} alt={event.title}/>}
                            <ContentDiv>
                                {event.bookingUrl && <BookingBullet>Rezerwacja</BookingBullet>}
                                <h3>{event.title}</h3>
                                <p>{event.address}</p>
                            </ContentDiv>
                        </ItemSection>
                        {!isMobile ? <VerticalSeparator/> : <HorizontalSeparator/>}
                        <ItemSection isMobile={isMobile}>
                            <DateTimeDiv>
                                {event.dateTimes.map((d, subIndex) => (
                                    <DayDiv key={subIndex}>
                                        <h2>{dateName(d.date)}</h2>
                                        <div>
                                            {d.timeSlots.map((timeslot, innerIndex) => (
                                                <p key={innerIndex}>{timeslot}</p>
                                            ))}
                                        </div>
                                    </DayDiv>
                                ))}
                            </DateTimeDiv>
                            {!isMobile && <Button
                                label="Poznaj szczegóły"
                                buttonStyle={BUTTON_STYLE.SECONDARY}
                                onClick={() => navigate(`${event.id}`)}
                            />}
                        </ItemSection>
                        {isMobile &&
                            <Center>
                                <Button
                                    label="Poznaj szczegóły"
                                    buttonStyle={BUTTON_STYLE.SECONDARY}
                                    onClick={() => navigate(`${event.id}`)}
                                />
                            </Center>}
                    </ListItem>
                </ListView>
            ))}
        </List>
    );
}

export default EventList


const Center = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px;
`

const ListView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: white;
  position: relative;
  top: 40px;
  margin-bottom: 10px;
`

const ListItem = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  justify-content: left;
  width: 100%;
  gap: 8px;
  margin: 0 auto;

  h5 {
    color: #1F0812;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.24px;
  }
`
const ItemSection = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => (isMobile ? '88%' : '48%')};
  display: flex;
  flex-direction: row;
  padding: 24px;
  gap: 20px;

  img {
    width: 168px;
    height: 134px;
    object-fit: cover;
  }

  button {
    width: 160px;
    height: 40px;
    position: relative;
    padding: 4px 8px;
    color: var(--Black, #1F0812);
    font-family: Arial;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    text-transform: none;
    margin: auto 0 0 auto;
`

const VerticalSeparator = styled.div`
  background: black;
  width: 1px;
  height: inherit;
  margin: 0 8px;
`

const HorizontalSeparator = styled.div`
  background: black;
  width: 91%;
  height: 1px;
  margin: 8px 0;
`

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-bottom: 5px;
  }

  p {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 20.8px */
    letter-spacing: 0.16px;
  }
`

const BookingBullet = styled.div`
  display: inline-flex;
  padding: 12px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: #FCE36E;
  color: #1F0812;
  font-family: Arial;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 100px;
  position: relative;
  margin-bottom: 18px;
\`     ;
`
const DateTimeDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  width: 300px;

  h2 {
    font-family: "Lato";
    font-size: 16px;
    text-transform: uppercase;
  }

  p {
    font-family: "Lato";
    font-size: 14px;
    padding-top: 6px;
  }

  @media (max-width: 500px) {
    margin-left: 10px;
    flex-wrap: wrap;

    h2 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
`

const DayDiv = styled.div`
  width: 120px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
`

const List = styled.div`
  margin-bottom: 50px;
  width: 94%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;


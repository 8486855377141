import React, {useEffect, useMemo, useState} from "react";
import {EventView, EventViewFilters, EventViewWeekdayFilters} from "../../Models/menu.model";
import styled from "styled-components";
import GdanskEventsHeader from "./components/GdanksEventsHeader";
import {fetchEvents} from "../../Utilities/fetchEvents";
import EventList from "./components/EventList";
import EventTile from "./components/EventTile";
import MapProvider from "../../Components/Map/MapProvider";
import GdanskEventsHeaderMobile from "./components/GdanskEventsHeaderMobile";

function GdanskDescription() {

    const [eventViewMode, setEventViewMode] = useState(EventView.tile)
    const [filteredEvents, setFilteredEvents] = useState(EventViewFilters.ALL);
    const [filteredEventsByDay, setFilteredEventsByDay] = useState(EventViewWeekdayFilters.ALL);
    const [events, setEvents] = useState([]);
    const [isMobile, setMobile] = useState(false);


    useEffect(() => {
        if (window.outerWidth < 1100) {
            setMobile(true);
        }
    }, []);


    useEffect(() => {
        const activeFestival = {id: 'jpzWCBrGUU2OLzJOrj5v'};
        fetchEvents(setEvents, activeFestival);
    }, []);


    function formatDateToPolish(dateString) {
        const daysOfWeek = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];
        const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getUTCDay()];
        const day = date.getUTCDate();
        const month = months[date.getUTCMonth()];
        return `${dayOfWeek}, ${day}.${month}`;
    }


    const filterAndSortEvents = (events, viewFilter, weekdayFilter) => {
        let filteredEvents = [...events]; // Copy all events

        if (viewFilter === EventViewFilters.RESERVATIONS) {
            filteredEvents = filteredEvents.filter(event => event.bookingUrl);
        } else if (viewFilter === EventViewFilters.NO_RESERVATIONS) {
            filteredEvents = filteredEvents.filter(event => !event.bookingUrl);
        }

        if (weekdayFilter !== EventViewWeekdayFilters.ALL) {
            // Filter events by the selected weekday
            const targetDay = getTargetDayOfWeek(weekdayFilter); // Implement getTargetDayOfWeek function
            filteredEvents = filteredEvents.filter(event =>
                event.dateTimes.some(dateTime => {
                    const eventDate = new Date(dateTime.date);
                    return eventDate.getDay() === targetDay;
                })
            );
        }

        // Sort the filtered events by name
        filteredEvents.sort((eventA, eventB) =>
            eventA.title.localeCompare(eventB.title)
        );

        return filteredEvents;
    };

    function getTargetDayOfWeek(weekdayFilter) {
        switch (weekdayFilter) {
            case EventViewWeekdayFilters.FRIDAY:
                return 5; // Friday corresponds to day 5
            case EventViewWeekdayFilters.SATURDAY:
                return 6; // Saturday corresponds to day 6
            case EventViewWeekdayFilters.SUNDAY:
                return 0; // Sunday corresponds to day 0
            default:
                return -1; // Return an invalid value for other cases
        }
    }

    const fridayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'FRIDAY'), [events, filteredEvents]);
    const saturdayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'SATURDAY'), [events, filteredEvents]);
    const sundayEvents = useMemo(() => filterAndSortEvents(events, filteredEvents, 'SUNDAY'), [events, filteredEvents]);

    const dateStringPiatek = "2023-09-29";
    const dateStringSobota = "2023-09-30";
    const dateStringNiedziela = "2023-10-01";
    const formattedDatePiatek = formatDateToPolish(dateStringPiatek);
    const formattedDateSobota = formatDateToPolish(dateStringSobota);
    const formattedDateNiedziela = formatDateToPolish(dateStringNiedziela);

    if (events.length === 0) {
        return <div>Loading...</div>
    }

    if (isMobile) {
        return (
            <MobileWrapper>
                <GdanskEventsHeaderMobile
                    eventViewMode={eventViewMode}
                    setEventViewMode={setEventViewMode}
                    filteredEvents={filteredEvents}
                    setFilteredEvents={setFilteredEvents}
                    filteredEventsByDay={filteredEventsByDay}
                    setFilteredEventsByDay={setFilteredEventsByDay}
                />
                {eventViewMode === EventView.list
                    && <EventList
                        events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}
                        isMobile={isMobile}/>}
                {eventViewMode === EventView.map
                    && <MapProvider events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}/>}
                {eventViewMode === EventView.tile
                    && <>
                        {fridayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.FRIDAY)
                            && <EventTiles isMobile={isMobile}>
                                {fridayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>}
                        {saturdayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.SATURDAY)
                            && <EventTiles isMobile={isMobile}>
                                {saturdayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>}
                        {sundayEvents
                            && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                                || filteredEventsByDay === EventViewWeekdayFilters.SUNDAY)
                            && <EventTiles isMobile={isMobile}>
                                {sundayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        }
                    </>}


            </MobileWrapper>
        )
    }


    return (
        <Wrapper>
            <Breadcrumb aria-label="Breadcrumb" className="breadcrumb">
                <ol>
                    <li>
                        <a href="/">
                            Strona Główna
                        </a>
                        <span>/</span>
                    </li>
                    <li>
                        <a href="/gdansk">
                            Gdańsk
                        </a>
                        <span>/</span>
                    </li>
                    <li>
                        <a href="/gdansk/events">
                            Program
                        </a>
                    </li>
                </ol>
            </Breadcrumb>



            <GdanskEventsHeader
                eventViewMode={eventViewMode}
                setEventViewMode={setEventViewMode}
                filteredEvents={filteredEvents}
                setFilteredEvents={setFilteredEvents}
                filteredEventsByDay={filteredEventsByDay}
                setFilteredEventsByDay={setFilteredEventsByDay}
            />

            {eventViewMode === EventView.list
                && <EventList
                    events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}
                    isMobile={isMobile}/>}
            {eventViewMode === EventView.map
                && <MapProvider events={filterAndSortEvents(events, filteredEvents, filteredEventsByDay)}/>}
            {eventViewMode === EventView.tile
                && <>
                    {fridayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.FRIDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDatePiatek}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {fridayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>
                    }
                    {saturdayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.SATURDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDateSobota}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {saturdayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>
                    }
                    {sundayEvents
                        && (filteredEventsByDay === EventViewWeekdayFilters.ALL
                            || filteredEventsByDay === EventViewWeekdayFilters.SUNDAY)
                        && <>
                            <Separator>
                                <h4>{formattedDateNiedziela}</h4>
                            </Separator>
                            <EventTiles isMobile={isMobile}>
                                {sundayEvents.map((event, index) => (
                                    <EventTile key={index} event={event} isMobile={isMobile}/>
                                ))}
                            </EventTiles>
                        </>}
                </>}
        </Wrapper>
    );
}

export default GdanskDescription


const Wrapper = styled.div`
  width: 1440px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  background-color: #F5F4F5;
  padding: 120px 40px 80px 40px;
`

const MobileWrapper = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  background-color: #F5F4F5;
  padding: 0;
`

const EventTiles = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => (isMobile ? '92vw' : '1440px')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  top: ${({isMobile}) => (isMobile ? '20px' : '40px')};
  gap: 30px;
  margin-top: 10px;
`

const Separator = styled.div`
  width: 95%;
  border-bottom: 1px solid black;
  position: relative;
  top: 40px;
  margin-top: 20px;

  h4 {
    color: #1B0466;
    font-family: Arial;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }
`

const Breadcrumb = styled.nav`
  ol {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  li {
    text-decoration: none;
    color: #514D58;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%
  }

  span {
    padding: 0 8px;
  }
`

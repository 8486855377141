import {useEffect, useState} from "react";
import {EventView, EventViewFilters} from "../../../Models/menu.model";
import styled from "styled-components";
import {fetchEvents} from "../../../Utilities/fetchEvents";
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import {useNavigate} from "react-router-dom";

interface EventTileProps {
    event: any;
    isMobile: boolean;
}

function EventTile({event, isMobile}: EventTileProps) {

    const navigate = useNavigate();

    return (
        <StyledEventTile isMobile={isMobile}>
            <ImageDiv isMobile={isMobile}>
                <BookingBullet>Rezerwacja</BookingBullet>
                <ImageWrapper>
                    <img src={event.images[0].url}/>
                </ImageWrapper>
            </ImageDiv>
            <ContentDiv isMobile={isMobile}>
                <h3>{event.title}</h3>
                <p>{event.address}</p>
                <Button label='Poznaj szczegóły' buttonStyle={BUTTON_STYLE.SECONDARY}
                        onClick={() => navigate(`${event.id}`)}/>
            </ContentDiv>
        </StyledEventTile>
    );
}

export default EventTile

const ContentDiv = styled.div<{isMobile:boolean}>`
  width: ${({isMobile}) => (isMobile ? '260px' : '350px')};
  height: 200px;
  background-color: white;
  z-index: 2;
  position: relative;
  top: -30px;
  margin: 0 auto;
  overflow: hidden;
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  button {
    width: 220px;
    position: relative;
    bottom: -20px;
    margin: auto auto 20px auto;
    color: var(--Black, #1F0812);

    p {
      text-align: center;
      font-family: Arial;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      text-transform: none;
    }
  }


  h3 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk;
    font-size: ${({isMobile}) => (isMobile ? '18px' : '20px')};
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    text-align: center;
    margin-bottom: 5px;
  }

  p {
    color: #1F0812;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    text-align: center;
  }

  ::before {
    content: '';
    position: absolute;
    display: block;
    top: 240px;
    left: 0;
    width: 200%;
    height: 10px; /* Yellow border height */
    background: linear-gradient(90deg,
    #af8ef5 0%,
    #e477f1 16.45%,
    #f19154 35.81%,
    #f5c119 45.53%,
    #FCE36E 51%);
    transform: translateX(-50%); /* Initially hide the gradient */
    transition: transform 0.3s ease; /* Add a transition effect for sliding */
    z-index: 999; /* Set a lower z-index for the pseudo-element */
  }
`
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide overflowing parts of the image */

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; /* Add a smooth transition */
  }
`;


const StyledEventTile = styled.div<{ isMobile: boolean }>`
  height: 640px;
  width: ${({isMobile}) => (isMobile ? '90vw' : '440px')};
  display: flex;
  flex-direction: column;

  :hover {
    ${ImageWrapper} img {
      transform: scale(1.2); /* Grow the image by 20% */
    }

    ${ContentDiv}::before {
      transform: translateX(0%);
    }
  }
`

const ImageDiv = styled.div<{ isMobile: boolean }>`
  width: ${({isMobile}) => (isMobile ? '90vw' : '440px')};
  height: 350px;

  img {
    width: ${({isMobile}) => (isMobile ? '90vw' : '440px')};
    height: 350px;
    object-fit: cover;
  }
`

const BookingBullet = styled.div`
  display: inline-flex;
  padding: 10px 18px;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background: #FCE36E;
  color: #1F0812;
  font-family: Arial;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  width: 120px;
  position: relative;
  float: right;
  right: 20px;
  top: 60px;
  z-index: 4;
`;

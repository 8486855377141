import React, {useEffect, useState} from 'react';
import L from "leaflet";
import * as ReactDOMServer from "react-dom/server";
import {ImLocation} from "react-icons/im";
import dateName from "../../../Utilities/dateConverter";
import useLocation from "../../../Utilities/Hooks/useLocation";
import {fetchEventById, fetchEvents} from "../../../Utilities/fetchEvents";
import {useNavigate, useParams} from "react-router-dom";
import {DocumentData} from "@firebase/firestore";
import BlogSlider from "../../../Pages/Blog/blogSlider";
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import styled from "styled-components";
import RecenterMap from "../../../Utilities/RecenterMap";
import YellowGradient from "../../../Components/Common/YellowGradient";
import MapControlsEvent from "../../../Components/Map/MapNavActiveEvent";
import HoverableButton from "../../../Components/Common/HoverableButton";
import {useAppDispatch, useAppSelector} from "../../../Store/hooks";
import {eventsSelector} from "../../../Store/selectors/eventsSelector";


const ActiveEventGdynia = () => {
    const {slug} = useParams();
    const events = useAppSelector(eventsSelector);
    const dispatch = useAppDispatch();
    const position = useLocation();
    const navigate = useNavigate();
    const [activeEvent, setActiveEvent] = useState<DocumentData | null>(null);
    const [prevEntryId, setPrevEntryId] = useState<string | null>(null);
    const [nextEntryId, setNextEntryId] = useState<string | null>(null);
    const [prevEntryTitle, setPrevEntryTitle] = useState<string | null>(null);
    const [nextEntryTitle, setNextEntryTitle] = useState<string | null>(null);
    const [prevEntryImage, setPrevEntryImage] = useState<string | null>(null);
    const [nextEntryImage, setNextEntryImage] = useState<string | null>(null);
    const [allEvents, setAllEvents] = useState<DocumentData[]>([]);
    const [isMobile, setMobile] = useState(false);


    useEffect(() => {
        if (window.outerWidth < 1100) {
            setMobile(true);
        }
    }, []);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [slug]);

    useEffect(() => {
        const activeFestival = {id: '5biwasplpC6qvxq42AX2'};
        fetchEvents(setAllEvents, activeFestival);
    }, []);


    useEffect(() => {
        async function fetchEvent() {
            const data = await fetchEventById(slug as NonNullable<string>);
            data !== null && setActiveEvent(data);
        }
        fetchEvent();
    }, [slug]);


    useEffect(() => {
        const sortedEvents = allEvents.sort((a, b) => {
            const titleA = a.title.toUpperCase();
            const titleB = b.title.toUpperCase();

            if (titleA < titleB) {
                return -1;
            }
            if (titleA > titleB) {
                return 1;
            }

            return 0;
        });


        const currentIndex = sortedEvents.sort((a, b) => a.title - b.title).findIndex((entry) => entry.id === slug);
        if (currentIndex !== -1) {
            const prevIndex = currentIndex - 1;
            const prevEntry = sortedEvents[prevIndex];

            const nextIndex = currentIndex + 1;
            const nextEntry = sortedEvents[nextIndex];


            setPrevEntryId(prevEntry ? prevEntry.id : null);
            setNextEntryId(nextEntry ? nextEntry.id : null);
            setPrevEntryTitle(prevEntry ? prevEntry.title : null);
            setNextEntryTitle(nextEntry ? nextEntry.title : null);
            setPrevEntryImage(prevEntry ? prevEntry.images[0].url : null);
            setNextEntryImage(nextEntry ? nextEntry.images[0].url : null);

        }
    }, [allEvents, slug]);

    if (!activeEvent) {
        return <div>Loading...</div>;
    }

    const MapIcon = () => {
        return L.divIcon({
            iconSize: [36, 52],
            iconAnchor: [15, 52],
            className: 'custom-div-icon',
            html: ReactDOMServer.renderToString(
                <div>
                    <YellowGradient/>
                    <ImLocation size={56} opacity={1} style={{fill: "#FFF75F"}}/>
                    <div className='circle'>{"Tu!"}</div>
                </div>
            ),
        });
    };

    const MapIcon2 = () => {
        const gradientId = 'RainbowGradient';
        const iconHtml = ReactDOMServer.renderToString(
            <div>
                <svg width="0" height="0">
                    <linearGradient id={gradientId} x1="100%" y1="100%" x2="0%" y2="0%">
                        <stop stopColor="#AF8EF5" offset="0%"/>
                        <stop stopColor="#E477F1" offset="32%"/>
                        <stop stopColor="#F19154" offset="91%"/>
                        <stop stopColor="F5C119" offset="100%"/>
                    </linearGradient>
                </svg>
                <ImLocation size={56} fill={`url(#${gradientId})`}/>
                <div className='circle'>{"Tu!"}</div>
            </div>
        );

        return L.divIcon({
            iconSize: [36, 52],
            iconAnchor: [15, 52],
            className: 'custom-div-icon',
            html: iconHtml,
        });
    };

    const description = activeEvent.description.replaceAll(' /n ', '');
    const midpoint = Math.ceil(description.length / 2 + 10);
    const splitIndex = description.indexOf(' ', midpoint);
    const firstColumnText = description.slice(0, splitIndex);
    const secondColumnText = description.slice(splitIndex + 1);

    return (activeEvent === null) ? null : (
        <Wrapper isMobile={isMobile}>
            <Breadcrumb aria-label="Breadcrumb" className="breadcrumb">
                <ol>
                    <li>
                        <a href="/">
                            Strona Główna
                        </a>
                        <span>/</span>
                    </li>
                    <li>
                        <a href="/gdansk">
                            Gdańsk
                        </a>
                        <span>/</span>
                    </li>
                    <li>
                        <a href="/gdansk/events">
                            Program
                        </a>
                        {isMobile && <span>/</span>}
                    </li>
                    {!isMobile &&
                        <li>
                            <a href={`/gdansk/events/${activeEvent.id}`}>
                                {activeEvent.title}
                            </a>
                        </li>}
                </ol>
            </Breadcrumb>
            {isMobile && <Breadcrumb>
                <ol>
                    <li>
                        <a href={`/gdansk/events/${activeEvent.id}`}>
                            {activeEvent.title}
                        </a>
                    </li>
                </ol>
            </Breadcrumb>}


            <ContentDiv isMobile={isMobile}>
                <LeftColumn isMobile={isMobile}>
                    {isMobile &&
                        <>
                            <h1>{activeEvent.title}</h1>
                            <DescriptionContainer isMobile={isMobile}>
                                <div>
                                    <p>{firstColumnText.split(' /n ').map((item, idx) => {
                                        return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                    })
                                    }</p>
                                </div>
                                <div>
                                    <p>{secondColumnText.split(' /n ').map((item, idx) => {
                                        return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                    })
                                    }</p>
                                </div>
                            </DescriptionContainer>

                            {activeEvent.title === "15. DALMOR" &&
                                <a href={'https://firebasestorage.googleapis.com/v0/b/ohf-app.appspot.com/o/documents%2FDALMOR-regulamin.pdf?alt=media&token=86f60266-0edc-4d78-bd6e-d95b902020f9'}
                                   download>Prosimy o pobranie i zapoznanie się z regulaminem wydarzenia</a>}
                        </>}
                    {!isMobile && <BlogSlider images={activeEvent.images} width={400}/>}
                    {(activeEvent.bookingUrl && !isMobile)
                        ?
                        <button id='booking'>Rezerwuję wejście</button>
                        : null}
                    <h2>Gdzie?</h2>
                    <p>{activeEvent.address}</p>
                    {activeEvent.address2 && <p>{activeEvent.address2}</p>}

                    <h2>O której ?</h2>
                    <DateTimeDiv>
                        {activeEvent.dateTimes.map((d, subIndex) => (
                            <DayDiv key={subIndex}>
                                <h3>{dateName(d.date)}</h3>
                                <div>
                                    {d.timeSlots.map((timeslot, innerIndex) => (
                                        <p key={innerIndex}>{timeslot.trim()}</p>
                                    ))}
                                </div>
                            </DayDiv>
                        ))}
                    </DateTimeDiv>
                    {(activeEvent.bookingUrl && isMobile)
                        ?
                        <button id='booking'>Rezerwuję wejście</button>
                        : null}
                </LeftColumn>
                <RightColumn isMobile={isMobile}>

                    {!isMobile &&
                        <>
                            <h1>{activeEvent.title}</h1>
                            <DescriptionContainer isMobile={isMobile}>
                                <div>
                                    <p>{firstColumnText.split(' /n ').map((item, idx) => {
                                        return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                    })
                                    }</p>
                                </div>
                                <div>
                                    <p>{secondColumnText.split(' /n ').map((item, idx) => {
                                        return (<span key={idx}>{item.replaceAll(' /n ', '')}<br/><br/></span>);
                                    })
                                    }</p>
                                </div>
                            </DescriptionContainer>

                            {activeEvent.title === "15. DALMOR" &&
                                <a href={'https://firebasestorage.googleapis.com/v0/b/ohf-app.appspot.com/o/documents%2FDALMOR-regulamin.pdf?alt=media&token=86f60266-0edc-4d78-bd6e-d95b902020f9'}
                                   download>Prosimy o pobranie i zapoznanie się z regulaminem wydarzenia</a>}
                        </>}
                    {isMobile &&
                        <SliderDiv>
                            <BlogSlider images={activeEvent.images} width={360}/>
                        </SliderDiv>}
                    <MapContainer id='map'
                                  center={activeEvent.position}
                                  zoom={18}
                                  maxZoom={17}
                                  minZoom={5}
                                  scrollWheelZoom={false}
                                  zoomControl={false}
                                  boxZoom={false}
                                  style={{
                                      width: isMobile ? '90vw' : '900px',
                                      height: '400px'
                                  }}>
                        <RecenterMap lat={activeEvent.position![0]} lng={activeEvent.position![1]}/>
                        <MapControlsEvent/>
                        <Marker position={activeEvent.position} icon={MapIcon()}></Marker>
                        <Marker position={position} icon={MapIcon2()}></Marker>

                        <TileLayer
                            url={'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png?api_key=e87fd70e-89e4-43ef-8d2a-2b12df2d984'}/>
                    </MapContainer>


                </RightColumn>
            </ContentDiv>
            <ButtonDiv>
                <HoverableButton
                    left={true}
                    blog={false}
                    selectedCity={'gdansk'}
                    id={prevEntryId as NonNullable<string>}
                    title={prevEntryTitle as NonNullable<string>}
                    buttonImageUrl={prevEntryImage as NonNullable<string>}
                    disabled={!prevEntryId}/>
                <HoverableButton
                    left={false}
                    blog={false}
                    selectedCity={'gdansk'}
                    id={nextEntryId as NonNullable<string>}
                    title={nextEntryTitle as NonNullable<string>}
                    buttonImageUrl={nextEntryImage as NonNullable<string>}
                    disabled={!nextEntryId}/>
            </ButtonDiv>
        </Wrapper>
    )
        ;
}

export default ActiveEventGdynia

const SliderDiv = styled.div`
    display: flex;
  justify-content: center;
  width: 92vw;
  
`


const ContentDiv = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${({isMobile}) => (isMobile ? 'column' : 'row')};
  justify-content: ${({isMobile}) => (isMobile ? 'center' : 'space-between')};
`

const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 18px;
`

const Wrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: ${({isMobile}) => (isMobile ? '12px' : ' 120px 40px 0px 40px')};
  gap: ${({isMobile}) => (isMobile ? '12px' : ' 24px')};

  h1 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  h3 {
    font-family: 'Cabinet Grotesk', sans - serif;
    font-size: 28px;
    font-weight: 400;
    padding: 0;
    margin: 0;
  }
`


const LeftColumn = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({isMobile}) => (isMobile ? '100%' : '400px')};
  gap: 10px;

  #booking {
    display: flex;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 60px;
    background: #E2FD5F;
    border: none;
    box-shadow: 8px 8px 0 0 #3E1DAA;
    color: #3E1DAA;
    font-family: Arial;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0.24px;
    width: 280px;
    margin: 20px auto;
  }

  p {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap
  }

  h2 {
    color: #1B0466;
    font-family: Cabinet Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin-top: 30px;
  }

`

const RightColumn = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: ${({isMobile}) => (isMobile ? '100%' : '900px')};
  gap: 40px;


  a {
    color: #f5f5f5;
    text-decoration: none;
    padding: 6px;
  }

  h1 {
    color: #3E1DAA;
    font-family: Cabinet Grotesk;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

`

const Breadcrumb = styled.nav`
  ol {
    list-style: none;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
  }

  li {
    text-decoration: none;
    color: #514D58;
    font-family: Arial;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%
  }

  span {
    padding: 0 8px;
  }
`

const DateTimeDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin-bottom: 16px;

  h3 {
    font-family: "Lato";
    font-size: 16px;
    text-transform: uppercase;
  }

  p {
    font-family: "Lato";
    font-size: 14px;
    padding-top: 6px;
  }

  @media (max-width: 500px) {
    margin-left: 10px;
    flex-wrap: wrap;

    h2 {
      font-size: 14px;
    }

    p {
      font-size: 12px;
    }
  }
`

const DayDiv = styled.div`
  width: 120px;

  div {
    display: flex;
    flex-direction: column;
    justify-content: left;
  }
`

const DescriptionContainer = styled.div<{ isMobile: boolean }>`
  display: ${({isMobile}) => (isMobile ? 'flex' : 'grid')};
  flex-direction: ${({isMobile}) => (isMobile && 'column')};
  grid-template-columns: ${({isMobile}) => (!isMobile && '1fr 1fr')};
  gap: ${({isMobile}) => (!isMobile && '16px')};
  color:  #514D58;
  font-family: Arial;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  div {
    margin-bottom: 6px;
  }
`;

import styled from "styled-components";
import Button from "../../../Components/Common/Button";
import {BUTTON_STYLE} from "../../../types/style";
import ICONS_VIEW from "../../../Assets/Autumn2023/Icons-view.svg";
import LIST_VIEW from "../../../Assets/Autumn2023/List-view.svg";
import MAP_VIEW from "../../../Assets/Autumn2023/map-view.svg";
import {EventView, EventViewFilters, EventViewWeekdayFilters} from "../../../Models/menu.model";
import {Dispatch, SetStateAction} from "react";

interface GdanskEventsHeaderProps {
    eventViewMode: EventView,
    setEventViewMode: Dispatch<SetStateAction<EventView>>
    filteredEvents: EventViewFilters;
    setFilteredEvents: Dispatch<SetStateAction<EventViewFilters>>
    filteredEventsByDay: EventViewWeekdayFilters;
    setFilteredEventsByDay: Dispatch<SetStateAction<EventViewWeekdayFilters>>
}

function GdanskEventsHeader({
                                eventViewMode,
                                setEventViewMode,
                                filteredEvents,
                                setFilteredEvents,
                                filteredEventsByDay,
                                setFilteredEventsByDay,
                            }: GdanskEventsHeaderProps) {

    return (
        <Wrapper style={{padding: 20}}>
            <FestivalDataDiv>
                <h1>Gdańsk</h1>
                <div>
                    <h2>29.09 – 01.10.2023</h2>
                    <p>Zaspa</p>
                </div>
            </FestivalDataDiv>
            <FiltersDiv>
                <div>
                    <h3>Widok</h3>
                    <div>
                    <Button
                        label="lista"
                        buttonStyle={eventViewMode === EventView.list
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        icon={LIST_VIEW}
                        onClick={() => setEventViewMode(EventView.list)}
                    />
                    <Button
                        label="kafelki"
                        buttonStyle={eventViewMode === EventView.tile
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        icon={ICONS_VIEW}
                        onClick={() => setEventViewMode(EventView.tile)}
                    />
                    <Button
                        label="mapa"
                        buttonStyle={eventViewMode === EventView.map
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        icon={MAP_VIEW}
                        onClick={() => setEventViewMode(EventView.map)}
                    />
                </div>
                </div>
                <div>
                    <h3>Rezerwacja</h3>
                    <div>
                    <Button
                        label="tak"
                        buttonStyle={filteredEvents === EventViewFilters.RESERVATIONS
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        onClick={() => setFilteredEvents(EventViewFilters.RESERVATIONS)
                        }/>
                    <Button label="nie"
                            buttonStyle={filteredEvents === EventViewFilters.NO_RESERVATIONS
                                ? BUTTON_STYLE.FILTERS_ACTIVE
                                : BUTTON_STYLE.FILTERS
                            }
                            onClick={() => setFilteredEvents(EventViewFilters.NO_RESERVATIONS)
                            }/>
                    <Button label="wszystkie"
                            buttonStyle={filteredEvents === EventViewFilters.ALL
                                ? BUTTON_STYLE.FILTERS_ACTIVE
                                : BUTTON_STYLE.FILTERS
                            }
                            onClick={() => setFilteredEvents(EventViewFilters.ALL)
                            }/>
                </div>
            </div>
                <div>
                    <h3>Dzień</h3>
                    <div>
                    <Button
                        label="piątek"
                        buttonStyle={filteredEventsByDay === EventViewWeekdayFilters.FRIDAY
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        onClick={() => setFilteredEventsByDay(EventViewWeekdayFilters.FRIDAY)
                        }/>
                    <Button
                        label="sobota"
                        buttonStyle={filteredEventsByDay === EventViewWeekdayFilters.SATURDAY
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        onClick={() => setFilteredEventsByDay(EventViewWeekdayFilters.SATURDAY)
                        }/>
                    <Button
                        label="niedziela"
                        buttonStyle={filteredEventsByDay === EventViewWeekdayFilters.SUNDAY
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        onClick={() => setFilteredEventsByDay(EventViewWeekdayFilters.SUNDAY)
                        }/>
                    <Button
                        label="wszystkie"
                        buttonStyle={filteredEventsByDay === EventViewWeekdayFilters.ALL
                            ? BUTTON_STYLE.FILTERS_ACTIVE
                            : BUTTON_STYLE.FILTERS
                        }
                        onClick={() => setFilteredEventsByDay(EventViewWeekdayFilters.ALL)
                        }/>
                    </div>
                </div>
            </FiltersDiv>
        </Wrapper>
    );
}

export default GdanskEventsHeader


const Wrapper = styled.div`
  width: 1320px;
  display: flex;
  flex-direction: column;
  padding: 40px;
  justify-content: space-between;
  position: relative;
`

const FestivalDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  color: #3E1DAA;

  h1 {
    font-family: Cabinet Grotesk;
    font-size: 140px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.4px;
  }

  & div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    h2 {
      font-family: Cabinet Grotesk;
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
    }

    p {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 130%;
      padding: 12px;
      background: #FCE36E;
      border-radius: 60px;
    }
  }

`

const FiltersDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  margin-top: 20px;
  
  &  div {
    display: flex;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    gap: 8px;
    
    & div{
      display: flex;
      flex-direction: row;
    }
    
    h3 {
      font-family: Arial;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      letter-spacing: 0.16px;
      color: black;
      margin-right: auto;
    }
  }
`


